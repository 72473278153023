<template>
  <base-section id="k-d-a-teams-header" space="0">
    <base-img :src="g_bLowestBr ? '' : require('@/assets/bg/bg-white-effect-1.png')">
      <v-container class="fill-height pa-0">
        <v-row :style="{ padding: `${g_bBaseBr ? '80px 28px' : '0 80px'}` }" no-gutters>
          <v-col cols="auto">
            <h1 :class="`kda-ts-${g_bLowestBr ? '28' : '80'}pt wt-extrabold`">
              {{ m_strTitle }}
            </h1>

            <p
              :class="`${g_bLowestBr ? 'kda-ts-12pt' : 'kda-ts-24pt pl-12'} nunito`"
              :x-large="$vuetify.breakpoint.mdAndUp"
            >
              {{ m_strDesc }}
            </p>
          </v-col>
        </v-row>
      </v-container>
    </base-img>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';
import BusinessDetails from '@/mixins/business-details';

export default {
  name: 'KDATeamsHeader',

  mixins: [BusinessDetails, BaseBreakpoint],

  computed: {
    m_strTitle() {
      return this.$vuetify.lang.t('$vuetify.kda.teams.header.title');
    },
    m_strDesc() {
      return this.$vuetify.lang.t('$vuetify.kda.teams.header.desc');
    }
  }
};
</script>
